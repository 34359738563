import {
	FxIdSdkAdapterSocialSettingDefault,
	FxIdSdkBaseAdapter,
	IExportedFxIdSdkMethod,
	IFxIdSdkAdapterBuyProductRequest,
	IFxIdSdkAdapterBuyProductResponse,
	IFxIdSdkAdapterInfoSocial,
	IFxIdSdkAdapterSocialSettings,
	IFxIdSdkAdapterStatEventRequest,
	IFxIdSdkAdapterStatInitializeRequest
} from "./FxIdSdkBaseAdapter";
import OpenApiClient from "../Api/OpenApiClient";
import {
	FxIdApplicationStoreCreatePaymentHandlerEmbeddingType,
	FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
	FxIdWebFeaturesPlayPublicDataBase,
	FxIdWebFeaturesStoreCreatePaymentRequest
} from "../Api/gen";
import { userStore } from "../Stores";
import { KongregateApi } from "../kongregate_api_sdk";

export interface IKongregateUser {
	userId: string;
	userName: string;
	gameAuthToken: string;
	isGuest: boolean;
}

export class FxIdSdkAdapterForKongregate extends FxIdSdkBaseAdapter {
	private _sdk!: KongregateApi;

	constructor(
		protected exportedSdk: IExportedFxIdSdkMethod,
		protected game: string,
		protected config: FxIdWebFeaturesPlayPublicDataBase
	) {
		super(exportedSdk);
	}

	SocialSettings(): Promise<IFxIdSdkAdapterSocialSettings> {
		return Promise.resolve(FxIdSdkAdapterSocialSettingDefault);
	}

	RegisterShareHandlers(): Promise<void> {
		return Promise.resolve();
	}

	async BuyProduct(request: IFxIdSdkAdapterBuyProductRequest): Promise<IFxIdSdkAdapterBuyProductResponse> {
		const userInfo = await this.GetPlayer();

		if (userInfo.isGuest) {
			this._sdk.services.showRegistrationBox();
			return { error: "guest" };
		}

		const paymentRequest: FxIdWebFeaturesStoreCreatePaymentRequest = {
			Game: this.game,
			Sku: request.sku,
			WebPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Kongregate,
			EmbeddingType: FxIdApplicationStoreCreatePaymentHandlerEmbeddingType.Embed,
			ProductDescriptionHint: request.productDescriptionHint,
			ProductNameHint: request.productNameHint
		};
		if (request.developerPayload != null) {
			paymentRequest.DeveloperPayload = {
				MerchantDeveloperPayload: request.developerPayload
			};
		}

		const createPaymentResult = await OpenApiClient.Store.fxIdWebFeaturesStoreCreatePaymentEndpoint(paymentRequest);

		log.info("Received result from server: %o", createPaymentResult);

		log.info("Calling kg api");
		this._sdk.mtx.purchaseItemsRemote(createPaymentResult.TransactionId, (result) => {
			if (!result.success) {
				log.error(result);
				return;
			}
			log.info("Kg api returned success: %o", result);
		});

		return {
			transactionId: createPaymentResult.TransactionId,
			stats: {
				currency: createPaymentResult.OrderProduct.Currency ?? "",
				currencyAmount: createPaymentResult.OrderProduct.Price ?? 0
			}
		};
	}

	async GetSocialInfo(): Promise<IFxIdSdkAdapterInfoSocial> {
		const userInfo = await this.GetPlayer();

		return Promise.resolve({
			social: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Kongregate,
			paymentsAvailable: true,
			userId: userInfo.userId,
			displayName: userInfo.userName,
			capabilities: []
		});
	}

	GetPlayer(): Promise<IKongregateUser> {
		const userId = this._sdk.services.getUserId();
		const userName = this._sdk.services.getUsername();
		const gameAuthToken = this._sdk.services.getGameAuthToken();
		const isGuest = this._sdk.services.isGuest();

		return Promise.resolve({ userId, userName, gameAuthToken, isGuest });
	}

	async Initialize(): Promise<void> {
		log.info("Initializing Kongregate adapter");

		log.info("Initializing Kongregate sdk");
		await new Promise<void>((resolve) => {
			const s = document.createElement("script");
			s.type = "text/javascript";
			s.src = `https://cdn1.kongregate.com/javascripts/kongregate_api.js`;
			s.addEventListener(
				"load",
				function (e) {
					log.info("Script loaded");
					resolve();
				},
				false
			);
			const head = document.getElementsByTagName("head")[0];
			head.appendChild(s);

			log.info("Script added");
		});

		log.info("Initializing kg api");
		this._sdk = await new Promise<KongregateApi>((resolve, reject) => {
			try {
				log.info("Loading kg api");
				window.kongregateAPI.loadAPI(() => {
					try {
						log.info("Getting kg api");
						const api = window.kongregateAPI.getAPI();
						resolve(api);
					} catch (e) {
						reject(e);
					}
				});
			} catch (ee) {
				reject(ee);
			}
		});

		log.info("Getting player");
		const player = await this.GetPlayer();

		userStore.getState().updateUserId(String(player.userId));
		userStore.getState().updateDisplayName(`#${player.userName ?? player.userName ?? player.userId}`);

		this._sdk.services.addEventListener("login", this.OnKongregateInPageLogin.bind(this));
	}

	OnKongregateInPageLogin() {
		log.info("Player authenticated. Reloading");
		document.location.reload();
	}

	async StoreCurrency(): Promise<string | undefined> {
		return Promise.resolve(undefined);
	}

	async StatInitialize(request: IFxIdSdkAdapterStatInitializeRequest): Promise<void> {
		return Promise.resolve();
	}

	StatEvent(request: IFxIdSdkAdapterStatEventRequest): Promise<void> {
		return Promise.resolve(undefined);
	}
}
