import {
	EFxIdSdkAdapterBuyProductResponseErrorCode,
	EFxIdSdkAdapterInfoSocialCapability,
	EFxIdSdkFocusChangedReason,
	EFxIdSdkGender,
	FxIdSdkAdapterSocialSettingDefault,
	FxIdSdkBaseAdapter,
	IExportedFxIdSdkMethod,
	IFxIdAddToFavoritesRequest,
	IFxIdAddToFavoritesResult,
	IFxIdJoinCommunityRequest,
	IFxIdJoinCommunityResult,
	IFxIdSdkAdapterBuyProductRequest,
	IFxIdSdkAdapterBuyProductResponse,
	IFxIdSdkAdapterGetFriendsRequest,
	IFxIdSdkAdapterInfoSocial,
	IFxIdSdkAdapterInviteFriendsRequest,
	IFxIdSdkAdapterSocialSettings,
	IFxIdSdkAdapterStatEventRequest,
	IFxIdSdkAdapterStatInitializeRequest,
	IFxIdSdkGetFriendsResult,
	IFxIdSdkGetFriendsResultFriend,
	IFxIdSdkInviteFriendsResult
} from "./FxIdSdkBaseAdapter";
import OpenApiClient from "../Api/OpenApiClient";
import {
	FxIdApplicationStoreCreatePaymentHandlerEmbeddingType,
	FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
	FxIdWebFeaturesPlayPublicDataBase,
	FxIdWebFeaturesStoreCreatePaymentRequest
} from "../Api/gen";
import vkBridge, { EAdsFormats, ErrorData, RequestPropsMap } from "@vkontakte/vk-bridge";
import { sharingHandlersStore, userStore } from "../Stores";
import { getI18n } from "react-i18next";

export class FxIdSdkAdapterForVkCom extends FxIdSdkBaseAdapter {
	private _adsRewardedVideoAvailable = false;
	private _adsInterstitialVideoAvailable = false;

	public static VkComDirectHost = "m.vk.com";
	private static VkComApiVersion = "5.131";

	constructor(
		protected exportedSdk: IExportedFxIdSdkMethod,
		protected game: string,
		protected config: FxIdWebFeaturesPlayPublicDataBase
	) {
		super(exportedSdk);
	}

	RegisterShareHandlers(): Promise<void> {
		const appId = this.config.PlatformData.VkontakteCom?.AppId;
		if (appId === undefined) {
			log.error("No App ID found in config. Skipping registering sharing handlers");
			return Promise.resolve();
		}
		sharingHandlersStore.getState().enableShareVk(() => {
			vkBridge
				.send("VKWebAppShowWallPostBox", {
					message: getI18n().t(`seo.${this.game}.message.vk`),
					attachments: `https://vk.com/app${appId}`,
					services: "twitter,facebook"
				})
				.then((data) => {
					if (data.post_id) {
						log.info(`Post added with id ${data.post_id}`);
					}
				})
				.catch((error) => {
					// Ошибка
					log.info(error);
				});
		});

		return Promise.resolve();
	}

	SocialSettings(): Promise<IFxIdSdkAdapterSocialSettings> {
		return Promise.resolve(FxIdSdkAdapterSocialSettingDefault);
	}

	async BuyProduct(request: IFxIdSdkAdapterBuyProductRequest): Promise<IFxIdSdkAdapterBuyProductResponse> {
		const paymentRequest: FxIdWebFeaturesStoreCreatePaymentRequest = {
			Game: this.game,
			Sku: request.sku,
			WebPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.VkontakteCom,
			EmbeddingType: FxIdApplicationStoreCreatePaymentHandlerEmbeddingType.Embed,
			ProductDescriptionHint: request.productDescriptionHint,
			ProductNameHint: request.productNameHint
		};

		if (request.developerPayload != null) {
			paymentRequest.DeveloperPayload = {
				MerchantDeveloperPayload: request.developerPayload
			};
		}

		const createPaymentResult = await OpenApiClient.Store.fxIdWebFeaturesStoreCreatePaymentEndpoint(paymentRequest);

		log.info("Received result from server: %o", createPaymentResult);

		try {
			// У VK нет возможности отправить произвольные параметры в запрос платежа, т.ч. идентификатор заказа отправляется в коде товара
			log.info(
				`Asking vk to buy item ${createPaymentResult.OrderProduct.Sku} with public id ${createPaymentResult.TransactionId}`
			);
			const result = await vkBridge.send("VKWebAppShowOrderBox", {
				type: "item",
				item: createPaymentResult.TransactionId
			});

			// На Алисе result.status внезапно начал присылать result как bool
			if (
				result.status === "success" ||
				(result.status as unknown as boolean) === true ||
				(result as unknown as any).success === true
			) {
				return {
					transactionId: createPaymentResult.TransactionId,
					stats: {
						currency: createPaymentResult.OrderProduct.Currency ?? "",
						currencyAmount: createPaymentResult.OrderProduct.Price ?? 0
					}
				};
			} else {
				return { error: `Vkontakte responded with error: ${JSON.stringify(result)}` };
			}
		} catch (e: any) {
			log.error("Error while buying product: %o", e);
			if (Object.prototype.hasOwnProperty.call(e, "error_type")) {
				const vkError = e as ErrorData;
				if (vkError.error_type === "client_error") {
					return {
						error: vkError.error_data.error_reason + " " + vkError.error_data.error_description,
						errorCode: this.MapVkErrorCode(vkError.error_data.error_code)
					};
				} else if (vkError.error_type === "api_error") {
					return { error: vkError.error_data.error_msg };
				} else if (vkError.error_type === "auth_error") {
					return { error: vkError.error_data.error_reason + " " + vkError.error_data.error_description };
				}
			}
			return { error: "Error while buying product" };
		}
	}

	private MapVkErrorCode(vkErrorCode: number): EFxIdSdkAdapterBuyProductResponseErrorCode {
		// https://dev.vk.com/ru/bridge/getting-started#%22error_type%22:%20%22client_error%22
		const VkErrorCodeUserDenied = 4;

		switch (vkErrorCode) {
			case VkErrorCodeUserDenied:
				return EFxIdSdkAdapterBuyProductResponseErrorCode.UserCancelled;
			default:
				return EFxIdSdkAdapterBuyProductResponseErrorCode.Unknown;
		}
	}

	async GetSocialInfo(): Promise<IFxIdSdkAdapterInfoSocial> {
		const appId = this.config.PlatformData.VkontakteCom?.AppId;
		if (appId == null) {
			throw new Error("No AppId");
		}

		const user = await vkBridge.send("VKWebAppGetUserInfo");

		const gender = this.MapVkGender(user.sex);

		let socialFlavor: IFxIdSdkAdapterInfoSocial["socialFlavor"] = undefined;

		const url = new URL(window.location.href);
		if (url.searchParams.has("direct") && url.searchParams.get("direct") === "true") {
			socialFlavor = "vkcom-direct";
		}

		if (socialFlavor == null) {
			const ancestorOrigins = location.ancestorOrigins;
			const url =
				ancestorOrigins == null || ancestorOrigins[0] == null
					? new URL(location.origin)
					: new URL(location.ancestorOrigins[0]);
			const host = url.host;
			if (host === FxIdSdkAdapterForVkCom.VkComDirectHost) {
				log.info("Detected vkcom direct");
				socialFlavor = "vkcom-direct";
			}
		}

		const accessTokenResult = await vkBridge.send("VKWebAppGetAuthToken", {
			app_id: appId,
			scope: ""
		});

		const vkComData: IFxIdSdkAdapterInfoSocial["vkcom"] = {
			appId,
			defaultApiVersion: FxIdSdkAdapterForVkCom.VkComApiVersion,
			defaultAccessToken: accessTokenResult.access_token
		};

		const result: IFxIdSdkAdapterInfoSocial = {
			social: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.VkontakteCom,
			paymentsAvailable: true,
			socialFlavor,
			userId: user.id.toString(),
			firstName: user.first_name,
			lastName: user.last_name,
			gender,
			photo: {
				width: 200,
				height: 200,
				url: user.photo_200
			},
			capabilities: [
				EFxIdSdkAdapterInfoSocialCapability.FriendsList,
				EFxIdSdkAdapterInfoSocialCapability.FriendsInvite,
				EFxIdSdkAdapterInfoSocialCapability.OutgoingLinksForbidden,
				EFxIdSdkAdapterInfoSocialCapability.AdsAvailable
			],
			vkcom: vkComData
		};

		return result;
	}

	async Initialize(): Promise<void> {
		log.info("Initializing VK com adapter");
		const result = await vkBridge.send("VKWebAppInit", {});
		if (!result.result) {
			throw new Error("Failed to initiialize vk bridge");
		}

		const player = await this.GetSocialInfo();

		userStore.getState().updateUserId(player.userId);
		userStore.getState().updateDisplayName(`#${player.userId}`);

		void this.CheckAdsAvailable();
	}

	async CheckAdsAvailable() {
		try {
			const data = await vkBridge.send("VKWebAppCheckNativeAds", { ad_format: EAdsFormats.REWARD });
			if (data.result) {
				log.info("Rewarded ads ready");
				this._adsRewardedVideoAvailable = true;
				window.FxIdSdk!.DispatchAdsVideoAvailable();
			} else {
				log.warn("Rewarded ads not found");
			}

			const dataInterstitial = await vkBridge.send("VKWebAppCheckNativeAds", {
				ad_format: EAdsFormats.INTERSTITIAL
			});
			if (dataInterstitial.result) {
				log.info("Interstitial ads ready");
				this._adsInterstitialVideoAvailable = true;
				window.FxIdSdk!.DispatchAdsInterstitialAvailable();
			} else {
				log.warn("Interstitial ads not found");
			}
		} catch (e) {
			log.error(e);
		}
	}

	StoreCurrency(): Promise<string | undefined> {
		return Promise.resolve(undefined);
	}

	StatInitialize(request: IFxIdSdkAdapterStatInitializeRequest): Promise<void> {
		return Promise.resolve(undefined);
	}

	StatEvent(request: IFxIdSdkAdapterStatEventRequest): Promise<void> {
		return Promise.resolve(undefined);
	}

	override async AdsShowVideo() {
		try {
			const data = await vkBridge.send("VKWebAppShowNativeAds", { ad_format: EAdsFormats.REWARD });
			if (data.result) {
				log.info("Ad shown");
				window.FxIdSdk!.DispatchAdsFinished();
			} else {
				log.warn("Ad failed");
				window.FxIdSdk!.DispatchAdsFailed();
			}
		} catch (e) {
			log.error(e);
		}
	}

	override IsFocusChanged() {
		type VkFocusChangedReason = "hide" | "pip";

		const eventHandler = (e: { detail: { type: string; data: { reason: VkFocusChangedReason } | object } }) => {
			const { type, data } = e.detail;

			if (type === "VKWebAppViewHide" && "reason" in data) {
				const currentReason = (() => {
					switch (data.reason) {
						case "hide":
							return EFxIdSdkFocusChangedReason.Hint;
						case "pip":
							return EFxIdSdkFocusChangedReason.Pip;
						default:
							return EFxIdSdkFocusChangedReason.Other;
					}
				})();

				const REASON_MESSAGES: Record<EFxIdSdkFocusChangedReason, string> = {
					[EFxIdSdkFocusChangedReason.Hint]:
						"пользователь свернул мини-приложение или игру или переключился на другое приложение на мобильном устройстве",
					[EFxIdSdkFocusChangedReason.Pip]:
						"пользователь свернул мини-приложение или игру, и она работает в режиме picture-in-picture («картинка в картинке»)",
					[EFxIdSdkFocusChangedReason.Other]: "другая причина"
				};

				this.exportedSdk.DispatchFocusChanged({
					focus: false,
					reason: currentReason,
					hint: REASON_MESSAGES[currentReason] || REASON_MESSAGES[EFxIdSdkFocusChangedReason.Other]
				});
			}

			if (type === "VKWebAppViewRestore") {
				this.exportedSdk.DispatchFocusChanged({ focus: true });
			}
		};
		vkBridge.subscribe(eventHandler);
	}

	override AdsIsVideoReady() {
		void this.CheckAdsAvailable();
		return Promise.resolve(this._adsRewardedVideoAvailable);
	}

	override async GetFriends(request: IFxIdSdkAdapterGetFriendsRequest): Promise<IFxIdSdkGetFriendsResult> {
		const appId = this.config.PlatformData.VkontakteCom?.AppId;
		if (appId == null) {
			throw new Error("No AppId");
		}

		log.info("Retrieving friends from VkCom");

		const accessTokenResult = await vkBridge.send("VKWebAppGetAuthToken", {
			app_id: appId,
			scope: "friends"
		});

		const getAppFriendsResult: { response: string[] } = await vkBridge.send("VKWebAppCallAPIMethod", {
			method: "friends.getAppUsers",
			params: {
				v: FxIdSdkAdapterForVkCom.VkComApiVersion,
				access_token: accessTokenResult.access_token
			}
		});

		log.info("getAppFriendsResult: %o", getAppFriendsResult);

		const appFriendsInfo: {
			response: Array<{
				id: number;
				photo_200: string;
				first_name: string;
				last_name: string;
				sex: number;
				can_access_closed: boolean;
				is_closed: boolean;
			}>;
		} = await vkBridge.send("VKWebAppCallAPIMethod", {
			method: "users.get",
			params: {
				user_ids: getAppFriendsResult.response.join(","),
				fields: "id,first_name,last_name,sex,photo_200",
				v: FxIdSdkAdapterForVkCom.VkComApiVersion,
				access_token: accessTokenResult.access_token
			}
		});

		log.info("appFriendsInfo: %o", appFriendsInfo);

		const result: IFxIdSdkGetFriendsResult = { friends: [] };

		for (const user of appFriendsInfo.response) {
			const gender = this.MapVkGender(user.sex);
			const friend: IFxIdSdkGetFriendsResultFriend = {
				uid: user.id.toString(),
				firstName: user.first_name,
				lastName: user.last_name,
				gender,
				photo: {
					width: 200,
					height: 200,
					url: user.photo_200
				}
			};

			result.friends.push(friend);
		}

		return result;
	}

	private MapVkGender(sex: number) {
		let gender: EFxIdSdkGender = EFxIdSdkGender.Unspecified;
		switch (sex) {
			case 1:
				gender = EFxIdSdkGender.Female;
				break;
			case 2:
				gender = EFxIdSdkGender.Male;
				break;
		}

		return gender;
	}

	override async InviteFriends(request: IFxIdSdkAdapterInviteFriendsRequest): Promise<IFxIdSdkInviteFriendsResult> {
		const data = await vkBridge.send("VKWebAppShowInviteBox", { request_id: request.trackString });

		return { friends: [] };
	}

	override async JoinCommunity(request: IFxIdJoinCommunityRequest): Promise<IFxIdJoinCommunityResult> {
		try {
			const data = await vkBridge.send("VKWebAppJoinGroup", { group_id: parseInt(request.community_id) });
			return { success: data.result };
		} catch (err: unknown) {
			log.error(err);
		}

		return { success: false };
	}

	override async AddToFavorites(request: IFxIdAddToFavoritesRequest): Promise<IFxIdAddToFavoritesResult> {
		try {
			const data = await vkBridge.send("VKWebAppAddToFavorites");
			return { success: data.result };
		} catch (err: unknown) {
			log.error(err);
		}

		return { success: false };
	}

	public async SendVkBridge(method: string, props: any) {
		return await vkBridge.send(method as keyof RequestPropsMap, props);
	}

	override AdsIsInterstitialReady() {
		return Promise.resolve(this._adsInterstitialVideoAvailable);
	}

	override async AdsShowInterstitial() {
		try {
			const data = await vkBridge.send("VKWebAppShowNativeAds", { ad_format: EAdsFormats.INTERSTITIAL });
			if (data.result) {
				log.info("Ad shown");
				window.FxIdSdk!.DispatchAdsFinished();
			} else {
				log.warn("Ad failed");
				window.FxIdSdk!.DispatchAdsFailed();
			}
		} catch (e) {
			log.error(e);
		}
		return Promise.resolve();
	}
}
