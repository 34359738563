/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdApplicationStoreProductDataLabels } from './FxIdApplicationStoreProductDataLabels';
import {
    FxIdApplicationStoreProductDataLabelsFromJSON,
    FxIdApplicationStoreProductDataLabelsFromJSONTyped,
    FxIdApplicationStoreProductDataLabelsToJSON,
    FxIdApplicationStoreProductDataLabelsToJSONTyped,
} from './FxIdApplicationStoreProductDataLabels';
import type { FxIdDomainStoreEnumsStoreProductType } from './FxIdDomainStoreEnumsStoreProductType';
import {
    FxIdDomainStoreEnumsStoreProductTypeFromJSON,
    FxIdDomainStoreEnumsStoreProductTypeFromJSONTyped,
    FxIdDomainStoreEnumsStoreProductTypeToJSON,
    FxIdDomainStoreEnumsStoreProductTypeToJSONTyped,
} from './FxIdDomainStoreEnumsStoreProductType';

/**
 * 
 * @export
 * @interface FxIdApplicationStoreOrderProductData
 */
export interface FxIdApplicationStoreOrderProductData {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    Sku: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    ProductName: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    ProductDescription: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    LocalizedProductName: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    LocalizedProductDescription: string;
    /**
     * 
     * @type {FxIdDomainStoreEnumsStoreProductType}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    ProductType: FxIdDomainStoreEnumsStoreProductType;
    /**
     * 
     * @type {FxIdApplicationStoreProductDataLabels}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    Labels?: FxIdApplicationStoreProductDataLabels | null;
    /**
     * 
     * @type {any}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    Payload?: any | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    Quantity: number;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    ImageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    IconUrl: string;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    ShowInWebStore: boolean;
    /**
     * 
     * @type {number}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    Price: number;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    Currency: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    Game: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    GameId: string;
}



/**
 * Check if a given object implements the FxIdApplicationStoreOrderProductData interface.
 */
export function instanceOfFxIdApplicationStoreOrderProductData(value: object): value is FxIdApplicationStoreOrderProductData {
    if (!('Sku' in value) || value['Sku'] === undefined) return false;
    if (!('ProductName' in value) || value['ProductName'] === undefined) return false;
    if (!('ProductDescription' in value) || value['ProductDescription'] === undefined) return false;
    if (!('LocalizedProductName' in value) || value['LocalizedProductName'] === undefined) return false;
    if (!('LocalizedProductDescription' in value) || value['LocalizedProductDescription'] === undefined) return false;
    if (!('ProductType' in value) || value['ProductType'] === undefined) return false;
    if (!('Quantity' in value) || value['Quantity'] === undefined) return false;
    if (!('ImageUrl' in value) || value['ImageUrl'] === undefined) return false;
    if (!('IconUrl' in value) || value['IconUrl'] === undefined) return false;
    if (!('ShowInWebStore' in value) || value['ShowInWebStore'] === undefined) return false;
    if (!('Price' in value) || value['Price'] === undefined) return false;
    if (!('Currency' in value) || value['Currency'] === undefined) return false;
    if (!('Game' in value) || value['Game'] === undefined) return false;
    if (!('GameId' in value) || value['GameId'] === undefined) return false;
    return true;
}

export function FxIdApplicationStoreOrderProductDataFromJSON(json: any): FxIdApplicationStoreOrderProductData {
    return FxIdApplicationStoreOrderProductDataFromJSONTyped(json, false);
}

export function FxIdApplicationStoreOrderProductDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreOrderProductData {
    if (json == null) {
        return json;
    }
    return {
        
        'Sku': json['Sku'],
        'ProductName': json['ProductName'],
        'ProductDescription': json['ProductDescription'],
        'LocalizedProductName': json['LocalizedProductName'],
        'LocalizedProductDescription': json['LocalizedProductDescription'],
        'ProductType': FxIdDomainStoreEnumsStoreProductTypeFromJSON(json['ProductType']),
        'Labels': json['Labels'] == null ? undefined : FxIdApplicationStoreProductDataLabelsFromJSON(json['Labels']),
        'Payload': json['Payload'] == null ? undefined : json['Payload'],
        'Quantity': json['Quantity'],
        'ImageUrl': json['ImageUrl'],
        'IconUrl': json['IconUrl'],
        'ShowInWebStore': json['ShowInWebStore'],
        'Price': json['Price'],
        'Currency': json['Currency'],
        'Game': json['Game'],
        'GameId': json['GameId'],
    };
}

export function FxIdApplicationStoreOrderProductDataToJSON(json: any): FxIdApplicationStoreOrderProductData {
    return FxIdApplicationStoreOrderProductDataToJSONTyped(json, false);
}

export function FxIdApplicationStoreOrderProductDataToJSONTyped(value?: FxIdApplicationStoreOrderProductData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Sku': value['Sku'],
        'ProductName': value['ProductName'],
        'ProductDescription': value['ProductDescription'],
        'LocalizedProductName': value['LocalizedProductName'],
        'LocalizedProductDescription': value['LocalizedProductDescription'],
        'ProductType': FxIdDomainStoreEnumsStoreProductTypeToJSON(value['ProductType']),
        'Labels': FxIdApplicationStoreProductDataLabelsToJSON(value['Labels']),
        'Payload': value['Payload'],
        'Quantity': value['Quantity'],
        'ImageUrl': value['ImageUrl'],
        'IconUrl': value['IconUrl'],
        'ShowInWebStore': value['ShowInWebStore'],
        'Price': value['Price'],
        'Currency': value['Currency'],
        'Game': value['Game'],
        'GameId': value['GameId'],
    };
}

