import {
	EFxIdSdkAdapterInfoSocialCapability,
	FxIdSdkAdapterSocialSettingDefault,
	FxIdSdkAdapterStickyBannerShowErrorReason,
	FxIdSdkBaseAdapter,
	IExportedFxIdSdkMethod,
	IFxIdSdkAdapterBuyProductRequest,
	IFxIdSdkAdapterBuyProductResponse,
	IFxIdSdkAdapterInfoSocial,
	IFxIdSdkAdapterSocialSettings,
	IFxIdSdkAdapterStatEventRequest,
	IFxIdSdkAdapterStatInitializeRequest,
	IFxIdSdkAdapterStickyBannerShowResultDto
} from "./FxIdSdkBaseAdapter";
import OpenApiClient from "../Api/OpenApiClient";
import {
	BaseAPI,
	FxIdApplicationStoreCreatePaymentHandlerEmbeddingType,
	FxIdDomainSettingsEGameOrientation,
	FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
	FxIdWebFeaturesPlayPublicDataBase,
	FxIdWebFeaturesStoreCreatePaymentRequest
} from "../Api/gen";
import { YaGamesPayments, YaGamesSdk, YaGamesSdkPlayer, YandexGamesPaymentsPurchase } from "../yagamessdk";
import { userStore } from "../Stores";

export class FxIdSdkAdapterForYandexGames extends FxIdSdkBaseAdapter {
	private sdk?: YaGamesSdk;

	constructor(
		protected exportedSdk: IExportedFxIdSdkMethod,
		protected game: string,
		protected config: FxIdWebFeaturesPlayPublicDataBase
	) {
		super(exportedSdk);
	}

	SocialSettings(): Promise<IFxIdSdkAdapterSocialSettings> {
		return Promise.resolve({ ...FxIdSdkAdapterSocialSettingDefault, ui: { disabled: true } });
	}

	RegisterShareHandlers(): Promise<void> {
		return Promise.resolve();
	}

	async BuyProduct(request: IFxIdSdkAdapterBuyProductRequest): Promise<IFxIdSdkAdapterBuyProductResponse> {
		const player = await this.sdk!.getPlayer({ scopes: false, signed: true });
		// if (player.getMode() === "lite") {
		// 	log.info("Player not authenticated. Authenticating");
		// 	await this.sdk!.auth.openAuthDialog();
		// 	log.info("Player authenticated. Reloading");
		// 	document.location.reload();
		// }

		// log.info("Player authenticated: %o", player);

		const yandexPayments = await this.sdk!.getPayments({ signed: true });

		const paymentRequest: FxIdWebFeaturesStoreCreatePaymentRequest = {
			Game: this.game,
			Sku: request.sku,
			WebPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.YandexGames,
			EmbeddingType: FxIdApplicationStoreCreatePaymentHandlerEmbeddingType.Embed,
			ProductDescriptionHint: request.productDescriptionHint,
			ProductNameHint: request.productNameHint
		};
		if (request.developerPayload != null) {
			paymentRequest.DeveloperPayload = {
				MerchantDeveloperPayload: request.developerPayload
			};
		}
		const createPaymentResult = await OpenApiClient.Store.fxIdWebFeaturesStoreCreatePaymentEndpoint(paymentRequest);

		log.info("Received result from server: %o", createPaymentResult);

		log.info("Requesting payment from yandex");
		const purchase = await yandexPayments.purchase({
			id: createPaymentResult.YandexGames!.StoreSpecificOrderProductSku,
			developerPayload: JSON.stringify({
				public_order_id: createPaymentResult.TransactionId
			})
		});

		await this.VerifyPurchase(purchase, yandexPayments);

		return {
			transactionId: createPaymentResult.TransactionId,
			stats: {
				currency: createPaymentResult.OrderProduct.Currency ?? "",
				currencyAmount: createPaymentResult.OrderProduct.Price ?? 0
			}
		};
	}

	private async VerifyPurchase(purchase: YandexGamesPaymentsPurchase, yandexPayments: YaGamesPayments) {
		log.info("Verifying payment on server %o", purchase);
		const response = await OpenApiClient.YandexGames.fxIdWebFeaturesStoreYandexGamesPurchaseEndpoint(this.game, {
			YandexSignedData: purchase.signature,
			YandexGetPurchasesRequest: false
		});

		log.info("Received response from server: %o", response);

		const responsePurchase = response.TokenSuccesses[0];

		if (!responsePurchase.Success) {
			throw new Error(`Purchase not validated ${JSON.stringify(responsePurchase.PurchaseToken)}`);
		}

		log.info("Purchase %s validated", responsePurchase.PurchaseToken);
		await yandexPayments.consumePurchase(responsePurchase.PurchaseToken);
	}

	async GetSocialInfo(): Promise<IFxIdSdkAdapterInfoSocial> {
		const userInfo = await this.GetPlayer();

		return Promise.resolve({
			social: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.YandexGames,
			paymentsAvailable: true,
			userId: userInfo.getUniqueID(),
			capabilities: [
				EFxIdSdkAdapterInfoSocialCapability.OutgoingLinksForbidden,
				EFxIdSdkAdapterInfoSocialCapability.AdsAvailable
			],
			socialLocale: this.sdk!.environment.i18n.lang
		});
	}

	async GetPlayer(): Promise<YaGamesSdkPlayer> {
		const userInfo = await this.sdk!.getPlayer({ scopes: false, signed: true });
		return userInfo;
	}

	async Initialize(): Promise<void> {
		log.info("Initializing Yandex adapter");

		await new Promise<void>((resolve) => {
			const s = document.createElement("script");
			s.type = "text/javascript";
			s.src = `https://sdk.games.s3.yandex.net/sdk.js`;
			s.addEventListener(
				"load",
				function (e) {
					log.info("Script loaded");
					resolve();
				},
				false
			);
			const head = document.getElementsByTagName("head")[0];
			head.appendChild(s);

			log.info("Script added");
		});

		log.info("Initializing yandex sdk");
		this.sdk = await window.YaGames.init({
			screen: {
				orientation: {
					value:
						this.config.GameOrientation === FxIdDomainSettingsEGameOrientation.Horizontal
							? "landscape"
							: "portrait",
					lock: true
				}
			}
		});

		log.info("Getting player");
		const player = await this.sdk!.getPlayer({ scopes: false, signed: true });

		userStore.getState().updateUserId(player.getUniqueID());
		userStore.getState().updateDisplayName(`#${player.getUniqueID()}`);

		this.CheckNotConsumedPurchases().then(log.info).catch(log.error);

		log.info("Yandex sdk environment: %o", this.sdk!.environment);
	}

	private async CheckNotConsumedPurchases() {
		log.info("Checking purchases");
		const yandexPayments = await this.sdk!.getPayments({ signed: true });

		const notConsumedPurchases = await yandexPayments.getPurchases();

		// Хотя в доках яндекса написано что тут дескать вернутся все покупки, по факту возвращаются не законсьюмленные и постоянные
		if (notConsumedPurchases.signature == null || notConsumedPurchases.signature.length === 0) {
			log.info("All purchases consumed");
			return;
		}

		log.info("Found not consumed purchases %o", notConsumedPurchases);

		log.info("Verifying payment on server %o", notConsumedPurchases);
		const response = await OpenApiClient.YandexGames.fxIdWebFeaturesStoreYandexGamesPurchaseEndpoint(this.game, {
			YandexSignedData: notConsumedPurchases.signature,
			YandexGetPurchasesRequest: true
		});

		log.info("Received response from server: %o", response);

		for (const token of response.TokenSuccesses) {
			if (!token.Success) {
				log.error("Purchase %s not validated", token.PurchaseToken);
			}

			log.info("Purchase %s validated", token);
			await yandexPayments.consumePurchase(token.PurchaseToken);
		}
	}

	StoreCurrency(): Promise<string | undefined> {
		return Promise.resolve(undefined);
	}

	StatInitialize(request: IFxIdSdkAdapterStatInitializeRequest): Promise<void> {
		// TODO: Это говнокод и его надо убрать - приложения должны вызывать LoadingReady
		this.sdk!.features.LoadingAPI?.ready();
		return Promise.resolve(undefined);
	}

	StatEvent(request: IFxIdSdkAdapterStatEventRequest): Promise<void> {
		return Promise.resolve(undefined);
	}

	override async StoreAdditionalData(): Promise<unknown | undefined> {
		const payments = await this.sdk!.getPayments({ signed: true });
		const catalog = await payments.getCatalog();

		return { catalog: catalog };
	}

	override AdsIsVideoReady() {
		window.FxIdSdk!.DispatchAdsVideoAvailable();
		return Promise.resolve(true);
	}

	override AdsShowVideo() {
		let finished = false;

		//Елена Дубровина https://chat.rbwtech.net/rbw/pl/5adbw4dqqpbamx4fgg164bjtmc
		// 8 minutes ago
		// @Михаил Варамашвили  нам надо знать когда окно закроется, потому что мы включаем выключаем игровую музыку,
		// если мы ее выключим на video_did_close - по логике что Алесей выше скинул, она у нас начнет играть до того как рекламное окно уберется.
		// Поэтому нам нужно знать когда окно закрывается чтоб ее включить
		//
		// ВРЕМЕННО отключаем новое поведение для рбв. Надо добавить DispatchAdsWindowClosed
		const WARNING_SKIPPED_EVENT_SENT_ALWAYS_EVENT_ON_ALREADY_FINISHED_AD =
			import.meta.env.VITE_APP_TENANT_OVERRIDE === "RBW";

		this.sdk!.adv.showRewardedVideo({
			callbacks: {
				onOpen: () => {
					log.info("Video ad open.");
				},
				onRewarded: () => {
					window.FxIdSdk!.DispatchAdsFinished();
					finished = true;
					log.info("Rewarded!");
				},
				onClose: () => {
					if (WARNING_SKIPPED_EVENT_SENT_ALWAYS_EVENT_ON_ALREADY_FINISHED_AD) {
						window.FxIdSdk!.DispatchAdsSkipped();
					} else {
						if (!finished) {
							window.FxIdSdk!.DispatchAdsSkipped();
						}
					}
					log.info("Video ad closed.");
				},
				onError: (e) => {
					window.FxIdSdk!.DispatchAdsFailed();
					log.error("Error while open video ad:", e);
				}
			}
		});

		return Promise.resolve();
	}

	override AdsIsInterstitialReady() {
		window.FxIdSdk!.DispatchAdsInterstitialAvailable();
		return Promise.resolve(true);
	}

	override AdsShowInterstitial() {
		this.sdk!.adv.showFullscreenAdv({
			callbacks: {
				onOpen: () => {
					log.info("InterstitalAdv ad open.");
				},
				onClose: function (wasShown: boolean) {
					if (wasShown) {
						window.FxIdSdk!.DispatchAdsFinished();
						log.info("Rewarded!");
					} else {
						window.FxIdSdk!.DispatchAdsSkipped();
						log.info("InterstitalAdv ad closed.");
					}
				},
				onError: function (error: Error) {
					window.FxIdSdk!.DispatchAdsFailed();
					log.error("Error while open video ad:", error.message);
				}
			}
		});

		return Promise.resolve();
	}

	override async AdsStickyBannerShow(): Promise<IFxIdSdkAdapterStickyBannerShowResultDto> {
		const { stickyAdvIsShowing, reason } = await this.sdk!.adv.getBannerAdvStatus();
		log.info("[YSDK] getBannerAdvStatus result. stickyAdvIsShowing: %o, reason: %o", stickyAdvIsShowing, reason);
		if (stickyAdvIsShowing) {
			return { success: true };
		} else if (reason) {
			// Реклама не показывается.
			log.info(reason);
			return {
				success: false,
				errorReason:
					reason === "ADV_IS_NOT_CONNECTED"
						? FxIdSdkAdapterStickyBannerShowErrorReason.AdsNotConnected
						: FxIdSdkAdapterStickyBannerShowErrorReason.Unknown
			};
		} else {
			const showResult = await this.sdk!.adv.showBannerAdv();
			log.info("[YSDK] showBannerAdv result. result: %o", showResult);
			if (showResult.stickyAdvIsShowing) {
				return { success: true };
			} else {
				return {
					success: false,
					errorReason:
						showResult.reason === "ADV_IS_NOT_CONNECTED"
							? FxIdSdkAdapterStickyBannerShowErrorReason.AdsNotConnected
							: FxIdSdkAdapterStickyBannerShowErrorReason.Unknown
				};
			}
		}
	}

	override async AdsStickyBannerHide(): Promise<IFxIdSdkAdapterStickyBannerShowResultDto> {
		const result = await this.sdk!.adv.hideBannerAdv();
		log.info("[YSDK] hideBannerAdv result: %o", result);

		return { success: true };
	}

	async LoadingReady(): Promise<void> {
		this.sdk!.features.LoadingAPI?.ready();
		void super.LoadingReady();
	}

	async GameStarted(): Promise<void> {
		this.sdk!.features.GameplayAPI?.start();
		void super.GameStarted();
	}

	async GameStopped(): Promise<void> {
		this.sdk!.features.GameplayAPI?.stop();
		void super.GameStopped();
	}
}
